<template>
  <div>
    <b-card :style="'min-height: 500px; background: ' + d_treeListData.style.backGround">
      <b-row style="margin: 0px;">
        <b-col v-for="(x, x_ind) in d_treeListData.list" cols="12" sm="12" lg="4">
          <b-card footer-class="px-3 py-2" style="height: 100%;">
            <b-row style="margin: 5px;">
              <b-col sm="12" md="12">
                <strong> {{ x.wdmr.label }} </strong>
              </b-col>
            </b-row>
            <template v-if="x.wdmr.data && x.wdmr.data.general && x.wdmr.data.general.info && x.wdmr.data.general.info.list && x.wdmr.data.general.info.list.length > 0">
              <template v-for="(info, info_ind) in x.wdmr.data.general.info.list">
                <b-row v-if="info && info.explanation && info.explanation.val" style="margin: 5px;">
                  <b-col sm="12" md="12">
                    <div v-html="f_getInfoHtml(info.explanation.val)"></div>
                  </b-col>
                </b-row>
                <b-row v-if="info && info.image && info.image.val" style="margin: 5px;">
                  <b-col sm="12" md="12">
                    <img :src="info.image.val" style="max-width: 75%;">
                  </b-col>
                </b-row>
              </template>
            </template>
            <div slot="footer">
              <b-link class="font-weight-bold font-xs btn-block text-muted" href="javascript:;" @click="f_goToDocumentDetails(x.key)">Detaylar <i class="fa fa-angle-right float-right font-lg"></i></b-link>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card style="background: black;" no-body>
      <web-bottom></web-bottom>
    </b-card>
  </div>
</template>

<script>
import WebBottom from '@/components/widgets/WebBottom';
import GlobalService from '@/services/global';
import WdmService from '@/services/wdm';
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import {
  default as router
} from '@/router';

export default {
  name: 'Howto',
  computed: {
    ...mapGetters({})
  },
  mounted: function () {},
  data () {
    return {
      d_showTree: false,
      d_treeListData: {
        'style': {
          'menu': {
            'background': '#ededed',
            'padding': '0',
            'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'border': 'solid 1px black'
          },
          'backGround': 'white',
          'item': {
            'background': '#ededed',
            'color': 'black',
            'margin': '3',
            'width': '300',
            'padding': '3',
            // 'boxShadow': 'rgb(164 219 221) -1px -1px 1px 1px',
            'boxShadow': '',
            'minHeight': '',
            // 'border': 'solid 1px black',
            'border': '',
            'left_plus': '10'
          }
        },
        'list': [],
        'template': {
          'type': '2'
        },
        'items': [],
        'parent_wdmr_data': '',
        'parent': { // parent and child field means: we can record a child to this parent. But other possible_relation means: this child's key can be added.
          'key': 'wdm232_5',
          'type': 'wdm232',
          'bucket': 'wisdom'
        },
        'child': {
          'reference_wdm_key': 'wdm_wisdom_wdm231_v2.0',
          'type': 'wdm231',
          'bucket': 'wisdom',
          'other_possible_relations': []
        }
      }
    };
  },
  created: function () {
    this.f_getWdmrTreeList();
  },
  filters: {},
  methods: {
    f_getWdmrTreeList: function () {
      let data = {
        'parent_wdm': this.d_treeListData.parent,
        'child_wdm': this.d_treeListData.child,
        'relation_type': 'keys',
        'type': 'get_wdmr_data', // only_relation_list or get_wdmr_data
        'layer_type': 'first' // first or all
      };
      WdmService.get_wdmr_tree_list(data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.d_treeListData.list = resp.data.result.list;
            let data = {
              'key': this.d_treeListData.parent.key,
              'bucket': this.d_treeListData.parent.bucket
            };
            // console.log(data);
            GlobalService.get_one_cb_document(data)
              .then(resp => {
                let msg = '';
                if (resp.data.status === "success") {
                  this.d_treeListData.parent_wdmr_data = resp.data.result;
                  this.d_showTree = true;
                } else {
                  console.log('errorr get_one_cb_document : ', resp.data.message);
                }
              });
          } else {
            console.log('errorr get_wdmr_tree_list : ', resp.data.message);
          }
        });
    },
    f_getInfoHtml: function (txt) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^\"#\&\?]*).*/;
      let match = txt.match(regExp);
      // console.log(match);
      let videoId = '';
      if (match && match.length > 2) {
        videoId = match[2];
      }
      // console.log('videoId', videoId);
      if (videoId) {
        let replace_text = '<figure class=\"media\"><oembed url=\"https://www.youtube.com/watch?v=' + videoId + '\"></oembed></figure>';
        let iframeMarkup = '<iframe max-width="100%" height="315" src="//www.youtube.com/embed/' + videoId + '" frameborder="0" allowfullscreen></iframe>';
        let starting_text = txt.substring(0, txt.indexOf(replace_text));
        if (starting_text.length > 300) {
          starting_text = starting_text.substring(0, 300) + '...';
        }
        return starting_text + iframeMarkup;
      } else {
        // console.log(txt)
        if (txt.length > 300) {
          return txt.substring(0, 300) + '...';
        } else {
          return txt;
        }
      }
    },
    f_goToDocumentDetails: function (wdmr231_key) {
      let route_data = {
        'name': 'documenttree',
        'path': 'documenttree',
        'query': {
          'wdmr231': wdmr231_key.split('_')[1]
        }
      };
      this.$router.push(route_data);
    }
  },
  components: {
    WebBottom
  }
};

</script>

